import {REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';

const INITIAL_STATE = {
  visibleRevenue: false,
  visibleView: false,
  approvalSearchReset: false,
};

const FETCH_FREIGHT_TABLE_DATA = 'FETCH_FREIGHT_TABLE_DATA';
const FETCH_EXCEPTIONS_TABLE_DATA = 'FETCH_EXCEPTIONS_TABLE_DATA';
const FETCH_DROP_DOWN_DATA__COMPLETED = 'FETCH_DROP_DOWN_DATA__COMPLETED';
const FETCH_BILL_NUMBER = 'FETCH_BILL_NUMBER';

// For FREIGHT
export const getDetailsSearchData = (
  search,
  changeUrl,
  showAllTrips = false,
) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/get-pending-trips/?all=${showAllTrips}${search}`;

    if ('cost' == changeUrl) {
      updateUrl = `finance/trip-cost-summary-details/${search}`;
    }

    dispatch({type: 'CLEAR_FREIGHT_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_FREIGHT_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateReduxData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: data.type,
      payload: data.data,
    });
  };
};

// re fetch

export const reFetch = (sendData, checkPage) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl;
    if (checkPage) {
      updateUrl = 'finance/cost-trip/fetch_base_rates/';
    } else {
      updateUrl = 'finance/trip-revenue/fetch_base_rates/';
    }
    try {
      response = await doRequest({
        method: REQUEST_TYPE['POST'],
        url: updateUrl,
        data: sendData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: 'FETCH_BASE_RATE_INFO',
          payload: {
            response,
            info: checkPage ? 'tripchargesInfo' : 'revenueInfo',
          },
        });
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const createCostVendorBaseRate = (allIds, sendData, rqmethod) => {
  return async (dispatch) => {
    let response = {};
    const {vendorId, baseRateInfoId} = allIds;
    let updateUrl = `/finance/vendor-base-rate/?vendor_id=${vendorId}&cost_capturing=true`;
    if (rqmethod == 'PATCH') {
      updateUrl = `/finance/vendor-base-rate/${baseRateInfoId}/?vendor_id=${vendorId}&cost_capturing=true`;
    }
    // if (sendData.my_id) {
    //   updateUrl = `finance/revenue-base-rate/${sendData.my_id}/?revenue_capturing=true&${changeUrl}`;
    // } else {
    //   updateUrl = `finance/revenue-base-rate/?revenue_capturing=true&${changeUrl}`;
    // }
    try {
      response = await doRequest({
        method: REQUEST_TYPE[rqmethod],
        url: updateUrl,
        data: sendData,
      });
      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: 'FETCH_FREIGHT_DETAILS_COST_TABLE_DATA_MODAL',
          payload: response,
        });
      } else {
        // const {message = 'Error'} = response || {};
        // dispatch({
        //   type: 'FREIGHT_FAILED_MODAL',
        //   payload: response,
        // });
      }
    } catch (err) {
      // dispatch({
      //   type: 'FREIGHT_FAILED_MODAL',
      //   payload: response,
      // });
    }
    return response;
  };
};

// checking
export const saveModalFrightData = (changeUrl, sendData, requestMethod) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl;
    if (sendData.my_id) {
      updateUrl = `finance/revenue-base-rate/${sendData.my_id}/?revenue_capturing=true&${changeUrl}`;
    } else {
      updateUrl = `finance/revenue-base-rate/?revenue_capturing=true&${changeUrl}`;
    }
    try {
      response = await doRequest({
        method: REQUEST_TYPE[requestMethod],
        url: updateUrl,
        data: sendData,
      });
      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: 'FETCH_FREIGHT_DETAILS_TABLE_DATA_MODAL',
          payload: response,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED_MODAL',
          payload: response,
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED_MODAL',
        payload: response,
      });
    }
    return response;
  };
};

export const getCostDetailsExceptionsData = (search = '', changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/trip-cost-summary?exceptions=true${search}`;

    // let updateUrl = `finance/trip-cost-summary-details?exceptions=true${search}`;

    if ('cost' == changeUrl) {
      updateUrl = `finance/trip-cost-summary-details?exceptions=true${search}`;
    }

    dispatch({type: 'CLEAR_FREIGHT_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_EXCEPTIONS_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getCostExceptionsSummaryData = (search = '', changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/trip-cost-summary?exceptions=true${search}`;

    // let updateUrl = `finance/trip-cost-summary-details?exceptions=true${search}`;

    // if ('cost' == changeUrl) {
    //   updateUrl = `finance/trip-cost-summary-details?exceptions=true${search}`;
    // }

    dispatch({type: 'CLEAR_FREIGHT_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_EXCEPTIONS_TABLE_DATA, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getExceptionsSummaryData = (search = '', showAllTrips = false) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/pending-tripscharges-count?exceptions=true&all=${showAllTrips}${search}`;

    dispatch({type: 'CLEAR_FREIGHT_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_EXCEPTIONS_TABLE_DATA, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getTableData = (search = '', changeUrl, showAllTrips = false) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/pending-tripscharges-count/?all=${showAllTrips}${search}`;

    if (changeUrl == 'COST') {
      updateUrl = `finance/trip-cost-summary/${search}`;
    }

    dispatch({type: 'CLEAR_FREIGHT_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_FREIGHT_TABLE_DATA, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// For Others

// DELETE IMAGE
export const deleteImage = (key, id) => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'CLEAR_OTHER_DETAILS'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/delete-trip-revenue-docs/${id.id}?customer_id=${id.customer_id}&others=true`,
        data: key,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'IMAGE_UPLOAD_UPDATE', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FETCH_TRIP_ADVANCE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FETCH_TRIP_ADVANCE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getOthersTableData = (
  search = '',
  changeUrl,
  showAllTrips = false,
) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/pending-tripscharges-count?others=true&all=${showAllTrips}${search}`;

    console.log('change', changeUrl);

    if ('COST' == changeUrl) {
      updateUrl = `finance/trip-cost-summary?others=true${search}`;
    }
    dispatch({type: 'CLEAR_OTHER_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_OTHERS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getCostExceptionDetailsSearchData = (search) => {
  //
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'CLEAR_OTHER_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/trip-cost-summary-details?exceptions=true${search}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_EXCEPTIONS_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getExceptionDetailsSearchData = (search, showAllTrips = false) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'CLEAR_OTHER_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/get-pending-trips?exceptions=true&all=${showAllTrips}${search}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_EXCEPTIONS_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getOtherDetailsSearchData = (
  search,
  changeUrl,
  showAllTrips = false,
) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl = `finance/get-pending-trips?others=true&all=${showAllTrips}${search}`;

    if (changeUrl == 'cost') {
      updateUrl = `finance/trip-cost-summary-details?others=true${search}`;
    }
    dispatch({type: 'CLEAR_OTHER_DETAILS'});
    dispatch({type: 'FREIGHT_LOADING'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_OTHERS_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const openSupportModal = (viewAdd, id, other_approval) => {
  return async (dispatch) => {
    if (viewAdd === 1) {
      dispatch({type: 'OPEN_SUPPORT_MODAL', payload: {id, other_approval}});
    }
    if (viewAdd == 2) {
      dispatch({
        type: 'OPEN_VIEW_SUPPORT_MODAL',
        payload: {id, other_approval},
      });
    }
  };
};

export const setFields = (n, f) => {
  return async (dispatch) => {
    if (n === 3) {
      dispatch({type: 'CURRENT_TAB', payload: f});
    }
  };
};

export const closeSupportModal = () => {
  return async (dispatch) => {
    dispatch({type: 'CLOSE_SUPPORT_MODAL'});
  };
};

export const updateRevenueSupportingDoc = (
  id,
  requestData,
  extra,
  changeUrl,
) => {
  return async (dispatch) => {
    let response = {};

    let updateUrl = `finance/upload-trip-revenue-docs/${id.id}?others=true&customer_id=${id.customer_id}${extra}`;

    switch (changeUrl) {
      case 'cost':
        updateUrl = `finance/upload-trip-cost-docs/${id.id}?approval=true&vendor_id=${id.customer_id}${extra}`;
        break;
      case 'costBilling':
        updateUrl = `finance/upload-trip-cost-docs/${id}`;
        break;
      case 'revBilling':
        updateUrl = `finance/upload-trip-revenue-docs/${id}`;
        break;
      case 'other_approval':
        updateUrl = `finance/upload-trip-revenue-docs/${id.id}?approval=true&kam_id=${id.customer_id}${extra}`;
        break;
    }
    try {
      // dispatch({type: UPDATE_TRIP_SUPPORTING_DOC});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: updateUrl,
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type:
            changeUrl === 'other_approval' || changeUrl === 'cost'
              ? 'FETCH_APPROVAL_DETAILS_TABLE_DATA'
              : 'FETCH_OTHERS_DETAILS_TABLE_DATA',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'UPDATE_TRIP_SUPPORTING_DOC_FAILED1',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'UPDATE_TRIP_SUPPORTING_DOC_FAILED1',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// For APPROVALS
export const getApprovalsSearchData = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/pending-tripscharges-count?approval=true&name=${search}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_APPROVAL_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FETCH_TRIP_ADVANCE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FETCH_TRIP_ADVANCE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getApprovalsTableData = (search = '', changeUrl) => {
  return async (dispatch) => {
    let response = {};

    let updateUrl = `finance/pending-tripscharges-count?approval=true${search}`;
    if ('COST' == changeUrl) {
      updateUrl = `finance/trip-cost-summary?approval=true${search}`;
    }

    dispatch({type: 'CLEAR_APPROVALS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_APPROVAL_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const resetApprovalSearchWhenTabChange = () => {
  return async (dispatch) => {
    dispatch({type: 'CLEAR_APPROVALS_SEARCH'});
  };
};

export const reFetchBaseRate = (sendData, checkPage) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl;
    if (checkPage) {
      updateUrl = 'finance/cost-trip/fetch_base_rates/';
    } else {
      updateUrl = 'finance/trip-revenue/fetch_base_rates/';
    }
    try {
      response = await doRequest({
        method: REQUEST_TYPE['POST'],
        url: updateUrl,
        data: sendData,
      });
      const {data, status} = response || {};

      if (status) {
        console.log('data', response);
        dispatch({
          type: checkPage
            ? 'FETCH_BASE_RATE_INFO_APPROVAL'
            : 'FETCH_BASE_RATE_INFO_APPROVAL_FREIGHT',
          payload: {
            response,
            info: checkPage ? 'tripchargesInfo' : 'revenueInfo',
          },
        });
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const updateTableData = (data) => {
  return async (dispatch) => {
    dispatch({type: 'FETCH_APPROVAL_DETAILS_TABLE_DATA', payload: data});
  };
};

// approval add update base rate

export const approvalAddUpdateBaseRate = (
  changeUrl,
  sendData,
  requestMethod,
) => {
  return async (dispatch) => {
    let response = {};
    let updateUrl;

    updateUrl = `finance/revenue-base-rate/${changeUrl}`;

    try {
      response = await doRequest({
        method: REQUEST_TYPE[requestMethod],
        url: updateUrl,
        data: sendData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'APPROVAL_ADD_UPDATE_BASE_RATE', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getApprovalsDetailsTableData = (params, changeUrl) => {
  return async (dispatch) => {
    let response = {};

    let updateUrl = `finance/get-pending-trips/?approval=true${params}`;
    if ('cost' == changeUrl) {
      updateUrl = `finance/trip-cost-summary-details?approval=true${params}`;
    }

    dispatch({type: 'CLEAR_APPROVALS'});
    dispatch({type: 'FREIGHT_LOADING'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: updateUrl,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_APPROVAL_DETAILS_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FREIGHT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FREIGHT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// reducers
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_FREIGHT_TABLE_DATA:
      return {
        ...state,
        allTableData: payload,
        freightTableDetailsData: [],
        freightLoading: false,
        st: {
          message: null,
          st_status: null,
          multiple: null,
        },
      };

    case FETCH_EXCEPTIONS_TABLE_DATA:
      return {
        ...state,
        allTableData: payload,
        otherDetailsTableData1111: [],
        freightLoading: false,
        approvalSearchReset: false,
      };

    case 'CLEAR_FREIGHT_DETAILS':
      return {
        ...state,
        allTableData: [],
        freightTableDetailsData: [],
        approvalSearchReset: false,
        exceptionDetailsData: [],
      };

    case 'FREIGHT_LOADING':
      return {
        ...state,
        freightLoading: true,
      };

    case 'FREIGHT_FAILED':
      return {
        ...state,
        freightLoading: false,
      };
    case 'FREIGHT_FAILED_MODAL':
      return {
        ...state,
        freightLoading: false,
        st: {
          message: payload.message,
          st_status: payload.status,
          multiple: payload.multiple,
        },
      };

    case 'FETCH_FREIGHT_DETAILS_COST_TABLE_DATA_MODAL':
      return {
        ...state,
        freightTableDetailsData: payload.data,
        freightLoading: false,
      };
    case 'FETCH_FREIGHT_DETAILS_TABLE_DATA_MODAL':
      return {
        ...state,
        freightTableDetailsData: payload.data,
        freightLoading: false,
        st: {
          message: payload.message,
          st_status: payload.status,
          multiple: payload?.multiple,
        },
      };
    case 'FETCH_BASE_RATE_INFO_APPROVAL':
      let approvalTableDetailsData = {
        approval_data: state.approvalTableDetailsData[
          localStorage.getItem('tab')
        ].map((item) => {
          const {trip_ids, trips} = payload.response.data;

          return trip_ids.includes(item.id)
            ? (item = {
                ...item,
                freight: trips[item.id][payload.info]?.freight
                  ? trips[item.id][payload.info]?.freight
                  : 0,
                same_as_base_rate:
                  trips[item.id][payload.info]?.same_as_base_rate &&
                  trips[item.id][payload.info]?.same_as_base_rate,
                detention: trips[item.id][payload.info]?.detention
                  ? trips[item.id][payload.info]?.detention
                  : 0,
                penalty: trips[item.id][payload.info]?.penalty
                  ? trips[item.id][payload.info]?.penalty
                  : 0,
                others: trips[item.id][payload.info]?.others
                  ? trips[item.id][payload.info]?.others
                  : 0,
                loading: trips[item.id][payload.info]?.loading
                  ? trips[item.id][payload.info]?.loading
                  : 0,
                distance:
                  payload.info == 'tripchargesInfo' &&
                  trips[item.id][payload.info]?.distance
                    ? trips[item.id][payload.info]?.distance
                    : 0,
                rate_agreed:
                  payload.info == 'tripchargesInfo' &&
                  trips[item.id][payload.info]?.rate_agreed
                    ? trips[item.id][payload.info]?.rate_agreed
                    : 0,
              })
            : item;
        }),
      };

      return {
        ...state,
        approvalTableDetailsData: {
          ...state.approvalTableDetailsData,
          [localStorage.getItem('tab')]: approvalTableDetailsData.approval_data,
        },
      };
    case 'FETCH_BASE_RATE_INFO_APPROVAL_FREIGHT':
      return {
        ...state,
        approvalTableDetailsData: {
          approval_freight_data: state.approvalTableDetailsData?.approval_freight_data.map(
            (item) => {
              const {trip_ids, trips} = payload.response.data;
              return trip_ids.includes(item.id)
                ? (item = {
                    ...item,
                    same_as_base_rate:
                      trips[item.id][payload.info]?.same_as_base_rate,
                    freight: trips[item.id][payload.info]?.freight
                      ? trips[item.id][payload.info]?.freight
                      : 0,
                  })
                : item;
            },
          ),
        },
      };

    case 'UPDATE_TABLE_DATA':
      return {
        ...state,
        freightTableDetailsData: {
          freight_data: payload,
        },
      };

    case 'FETCH_BASE_RATE_INFO':
      return {
        ...state,
        freightTableDetailsData: {
          freight_data: state.freightTableDetailsData.freight_data.map(
            (item) => {
              const {trip_ids, trips} = payload.response.data;
              return trip_ids.includes(item.id)
                ? {
                    ...item,
                    freight: trips[item.id][payload.info]?.freight
                      ? trips[item.id][payload.info]?.freight
                      : 0,

                    distance:
                      payload.info == 'tripchargesInfo' &&
                      trips[item.id][payload.info]?.distance
                        ? trips[item.id][payload.info]?.distance
                        : 0,

                    same_as_base_rate:
                      trips[item.id]?.[payload.info]?.same_as_base_rate || '',
                    rate_agreed:
                      payload.info == 'tripchargesInfo' &&
                      trips[item.id][payload.info]?.rate_agreed
                        ? trips[item.id][payload.info]?.rate_agreed
                        : 0,
                  }
                : item;
            },
          ),
        },
      };

    case 'FETCH_FREIGHT_DETAILS_TABLE_DATA':
      return {
        ...state,
        freightTableDetailsData: payload,
        freightLoading: false,
      };

    case 'CURRENT_TAB':
      return {
        ...state,
        visibleRevenue: true,
        visibleView: false,
        activeKey: payload,
      };

    case 'OPEN_SUPPORT_MODAL':
      return {
        ...state,
        visibleRevenue: true,
        visibleView: false,
        revenueId: payload.id,
        other_approval: payload.other_approval,
        // activeKey: '',
        approvalSearchReset: false,
      };

    case 'OPEN_VIEW_SUPPORT_MODAL':
      return {
        ...state,
        visibleView: true,
        revenueId: payload.id,
        other_approval: payload.other_approval,
        // activeKey: '',
      };
    case 'CLOSE_SUPPORT_MODAL':
      return {
        ...state,
        visibleRevenue: false,
        visibleView: false,
        approvalSearchReset: false,
      };

    case 'FETCH_OTHERS_TABLE_DATA':
      return {
        ...state,
        otherTableData: payload,
        otherDetailsTableData1111: [],
        freightLoading: false,
        approvalSearchReset: false,
      };

    case 'CLEAR_OTHER_DETAILS':
      return {
        ...state,
        exceptionDetailsData: [],
        otherTableData: [],
        otherDetailsTableData1111: [],
        approvalSearchReset: false,
      };

    case 'IMAGE_UPLOAD_UPDATE':
      return {
        ...state,
        visibleRevenue: false,
        visibleView: false,
        otherDetailsTableData1111: payload,
      };

    case 'FETCH_OTHERS_DETAILS_TABLE_DATA':
      return {
        ...state,
        otherDetailsTableData1111: payload,
        freightLoading: false,
      };

    case 'FETCH_EXCEPTIONS_DETAILS_TABLE_DATA':
      return {
        ...state,
        exceptionDetailsData: payload,
        freightLoading: false,
      };

    case 'FETCH_APPROVAL_TABLE_DATA':
      return {
        ...state,
        approvalTableData: payload,
        freightLoading: false,
        approvalSearchReset: false,
        // approvalTableDetailsData: [],
      };

    case 'CLEAR_APPROVALS_SEARCH':
      return {
        ...state,
        approvalSearchReset: true,
      };

    case 'CLEAR_APPROVALS':
      return {
        ...state,
        approvalTableDetailsData: [],
        approvalTableData: [],
        approvalSearchReset: false,
        freightLoading: false,
      };

    case 'APPROVAL_ADD_UPDATE_BASE_RATE':
      return {
        ...state,
        approvalTableDetailsData: payload,
        freightLoading: false,
      };

    case 'FETCH_APPROVAL_DETAILS_TABLE_DATA':
      return {
        ...state,
        approvalTableDetailsData: payload,
        freightLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
