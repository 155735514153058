import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Bookings} from '../../helpers/Urls';
import {
  FETCH_CITIES_BY_CUSTOMER,
  FETCH_CITIES_BY_CUSTOMER_COMPLETED,
  FETCH_CITIES_BY_CUSTOMER_FAILED,
} from '../pages/createBooking';

const INITIAL_STATE = {
  isCommentFetching: false,
};

export const FETCH_BOOKING_BY_ID = 'FETCH_BOOKING_BY_ID';
export const FETCH_BOOKING_BY_ID_COMPLETED = 'FETCH_BOOKING_BY_ID_COMPLETED';
export const FETCH_BOOKING_BY_ID_FAILED = 'FETCH_BOOKING_BY_ID_FAILED';

export const FETCH_BOOKING_DETAILS = 'FETCH_BOOKING_DETAILS';
export const FETCH_BOOKING_DETAILS_COMPLETED =
  'FETCH_BOOKING_DETAILS_COMPLETED';
export const FETCH_BOOKING_DETAILS_FAILED = 'FETCH_BOOKING_DETAILS_FAILED';

export const REVIVE_BOOKING = 'REVIVE_BOOKING';
export const REVIVE_BOOKING_COMPLETED = 'REVIVE_BOOKING_COMPLETED';
export const REVIVE_BOOKING_FAILED = 'REVIVE_BOOKING_FAILED';

export const REJECT_BOOKING = 'REJECT_BOOKING';
export const REJECT_BOOKING_COMPLETED = 'REJECT_BOOKING_COMPLETED';
export const REJECT_BOOKING_FAILED = 'REJECT_BOOKING_FAILED';

export const DEACTIVATE_BOOKING = 'DEACTIVATE_BOOKING';
export const DEACTIVATE_BOOKING_COMPLETED = 'DEACTIVATE_BOOKING_COMPLETED';
export const DEACTIVATE_BOOKING_FAILED = 'DEACTIVATE_BOOKING_FAILED';

export const ASSIGN_BOOKING = 'ASSIGN_BOOKING';
export const ASSIGN_BOOKING_COMPLETED = 'ASSIGN_BOOKING_COMPLETED';
export const ASSIGN_BOOKING_FAILED = 'ASSIGN_BOOKING_FAILED';

export const REASSIGN_BOOKING = 'REASSIGN_BOOKING';
export const REASSIGN_BOOKING_COMPLETED = 'REASSIGN_BOOKING_COMPLETED';
export const REASSIGN_BOOKING_FAILED = 'REASSIGN_BOOKING_FAILED';

export const DOCK_BOOKING = 'DOCK_BOOKING';
export const DOCK_BOOKING_COMPLETED = 'DOCK_BOOKING_COMPLETED';
export const DOCK_BOOKING_FAILED = 'DOCK_BOOKING_FAILED';

export const CHECK_DOCK_CONDITION = 'CHECK_DOCK_CONDITION';
export const CHECK_DOCK_CONDITION_COMPLETED = 'CHECK_DOCK_CONDITION_COMPLETED';
export const CHECK_DOCK_CONDITION_FAILED = 'CHECK_DOCK_CONDITION_FAILED';

export const FETCH_TAT_AND_DISTANCE = 'FETCH_TAT_AND_DISTANCE';
export const FETCH_TAT_AND_DISTANCE_COMPLETED =
  'FETCH_TAT_AND_DISTANCE_COMPLETED';
export const FETCH_TAT_AND_DISTANCE_FAILED = 'FETCH_TAT_AND_DISTANCE_FAILED';

export const ACTIVATE_BOOKING = 'ACTIVATE_BOOKING';
export const ACTIVATE_BOOKING_COMPLETED = 'ACTIVATE_BOOKING_COMPLETED';
export const ACTIVATE_BOOKING_FAILED = 'ACTIVATE_BOOKING_FAILED';

export const FETCH_BOOKING_COMMENTS = 'FETCH_BOOKING_COMMENTS';
export const FETCH_BOOKING_COMMENTS_COMPLETED =
  'FETCH_BOOKING_COMMENTS_COMPLETED';
export const FETCH_BOOKING_COMMENTS_FAILED = 'FETCH_BOOKING_COMMENTS_FAILED';

export const ADDING_BOOKING_COMMENT = 'ADDING_BOOKING_COMMENT';
export const ADDING_BOOKING_COMMENT_COMPLETED =
  'ADDING_BOOKING_COMMENT_COMPLETED';
export const ADDING_BOOKING_COMMENT_FAILED = 'ADDING_BOOKING_COMMENT_FAILED';

export const ADD_BOOKINGS_LOCATION = 'ADD_BOOKINGS_LOCATION';
export const ADD_BOOKINGS_LOCATION_COMPLETED =
  'ADD_BOOKINGS_LOCATION_COMPLETED';
export const ADD_BOOKINGS_LOCATION_FAILED = 'ADD_BOOKINGS_LOCATION_FAILED';

export const fetchTatAndDistance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TAT_AND_DISTANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.fetchTatAndDistanceURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TAT_AND_DISTANCE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TAT_AND_DISTANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TAT_AND_DISTANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchBookingDetails = (booking_id, url) => {
  return async (dispatch) => {
    let response = {};
    console.log('tracking history ---call', booking_id, url);
    try {
      dispatch({type: FETCH_BOOKING_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchBookingDetailsURL(booking_id, url),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_BOOKING_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_BOOKING_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BOOKING_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const reviveBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REVIVE_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.reviveBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: REVIVE_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: REVIVE_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: REVIVE_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const rejectBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REJECT_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.rejectBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: REJECT_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: REJECT_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: REJECT_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deactivateBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DEACTIVATE_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.deactivateBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DEACTIVATE_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DEACTIVATE_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DEACTIVATE_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchStatusOfLock = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      // dispatch({type: ASSIGN_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `vehicle/get-vehicle-elock-status?${requestData}`,

        // url: `vehicle/get-vehicle-elock-status?vehicle_id=${requestData.vehicleNumber}`,
      });
      console.log('response --EDIT_TRIP_VEHICLE--', response);
      const {data, status} = response || {};
      if (status === true) {
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const assignBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ASSIGN_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.assignBookingURL(),
        data: requestData,
        // isMAPI: true,
      });
      console.log('response --EDIT_TRIP_VEHICLE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ASSIGN_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ASSIGN_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ASSIGN_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const reassignBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REASSIGN_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.reassignBookingURL(),
        data: requestData,
        // isMAPI: true,
      });
      console.log('response --EDIT_TRIP_VEHICLE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: REASSIGN_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: REASSIGN_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: REASSIGN_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const dockBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOCK_BOOKING});
      console.log('requestData ----', requestData);
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.dockBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DOCK_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DOCK_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOCK_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchBookingById = (booking_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_BOOKING_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.fetchBookingByIdURL(),
        data: {booking_id},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_BOOKING_BY_ID_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_BOOKING_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BOOKING_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const checkDockConditions = (booking_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_DOCK_CONDITION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.checkDockConditionURL(),
        data: {booking_id},
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        const {answers} = data;
        localStorage.setItem('answers', JSON.stringify(answers));
        dispatch({
          type: CHECK_DOCK_CONDITION_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHECK_DOCK_CONDITION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_DOCK_CONDITION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const activateBooking = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ACTIVATE_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.activateBookingURL(),
        data: requestData,
      });
      console.log('response --CHECK_TRIP_BEGIN_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ACTIVATE_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ACTIVATE_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ACTIVATE_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchBookingComments = (bookingId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_BOOKING_COMMENTS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchBookingCommentsURL(bookingId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_BOOKING_COMMENTS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_BOOKING_COMMENTS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BOOKING_COMMENTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//requestData={ booking_id, comment: "1123"}
export const addBookingComment = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    const {bookingId, comment} = requestData;
    try {
      console.log('AddBookingCommentfailed....1');
      dispatch({type: ADDING_BOOKING_COMMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.addBookingCommentsURL(bookingId),
        data: {
          comment,
        },
        isMAPI: true,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: ADDING_BOOKING_COMMENT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADDING_BOOKING_COMMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADDING_BOOKING_COMMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addBookingsLocation = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_BOOKINGS_LOCATION});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.addBookingsLocationURL(),
        data: requestData,
      });
      console.log('response --ADD_BOOKINGS_LOCATION--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_BOOKINGS_LOCATION_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_BOOKINGS_LOCATION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_BOOKINGS_LOCATION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case ADDING_BOOKING_COMMENT:
      return {...state, isCommentFetching: true};
    case ADDING_BOOKING_COMMENT_COMPLETED:
      return {...state, isCommentFetching: false};
    case ADDING_BOOKING_COMMENT_FAILED:
      return {...state, isCommentFetching: false};
    default: {
      if (payload.bookings) {
        return {...state, ...payload.bookings};
      }
      return state;
    }
  }
};
